.theme-dark.MaterialForm {
  color: var(--gray-blue);
  background-color: transparent;
  border-color: #23292d !important;
}

.theme-dark.MaterialForm .list-group-item {
  color: #848d97 !important;
  background-color: #0a0c0f !important;
  border: 1px solid #161b22;
}

.theme-dark.MaterialForm .tw-bg-white {
  background-color: #161b22 !important;
}

.theme-dark.MaterialForm .card-header {
  color: #999999;
  /* background-color: #161b22; */
  border-color: #23292d !important;
}

.theme-dark.MaterialForm .card-footer {
  /* background: #161b22; */
  border-color: #23292d !important;
}

/* Form Elements */
.theme-dark.MaterialForm input[type="text"],
.theme-dark.MaterialForm input[type="number"],
.theme-dark.MaterialForm input[type="checkbox"],
.theme-dark.MaterialForm textarea {
  color: #c0c7cb !important;
  background-color: #222f3e !important;
  border-color: #161f29 !important;
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #6d7681 !important;
}

::placeholder {
  color: #6d7681 !important;
}

.theme-dark.MaterialForm .badge.bg-white,
.theme-dark.MaterialForm .badge.badge-primary {
  color: #2c73da !important;
  background-color: #131d2e !important;
  border-color: #23292e !important;
}

.theme-dark.MaterialForm .input-group-text {
  color: var(--gray-blue) !important;
  background-color: #161b22 !important;
  border-color: #23292e !important;
}