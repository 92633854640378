.th-border-top-0 th {
  border-top: 0 !important;
}

.table-border-top-0 {
  border-top: 0 !important;
}

.table-border-top-0 th {
  border-top: 0 !important;
}

.row-clickable {
  cursor: pointer;
}

.row-clickable:hover {
  background-color: rgba(54, 162, 235, 0.2);
}
