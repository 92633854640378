
.mce-content-body {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.mce-content-body figure.align-left {
  float: left;
}
.mce-content-body figure.align-right {
  float: right;
}
.mce-content-body figure.image.align-center {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767.98px) {
  .mce-content-body img {
    width: 100%;
    height: auto;
  }
}