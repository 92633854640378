* {
  box-sizing: border-box;
}

body {
  background-color: #121212;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

/* FIX TOOLTIP */
.__react_component_tooltip.place-top::before {
  z-index: -1 !important;
}

p {
  font-size: 1.125rem;
}

small p {
  font-size: 1rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.fa {
  font-size: 22px;
  transform: translateY(50deg);
}

.feather {
  height: 16px;
  vertical-align: text-bottom;
  width: 16px;
}

[role="main"] {
  padding-top: 18px;
}

.opacity-0 {
  opacity: 0;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.text-muted a {
  color: #1b9cfc;
}

.text-break-all {
  word-break: break-all;
}

.text-ellipsis {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.overflow-content {
  overflow-x: scroll;
  overflow-y: auto;
}

.pointer-events-none {
  pointer-events: none;
}

.privacy-blur {
  filter: blur(3px);
}

.privacy-blur * {
  filter: blur(3px);
}

/** UI **/

.ColoredBox {
  background-color: #1d1d1d;
  font-weight: bold;
  min-height: 120px;
}

.Box {
  color: #e2e2e2;
  font-weight: bold;
  min-height: 120px;
}

.BoxText {
  color: #e2e2e2;
  font-weight: bold;
  font-size: 1.25rem;
}

.BoxSubText {
  color: #999999;
  /* font-weight: bold; */
  font-size: 1rem;
}

.TextPurple {
  /* span */
  color: #bb86fc;
}

.TextYellow {
  /* span */
  color: #fcd686;
}

.TextBlue {
  /* span */
  color: #86c4fc;
}

.Row {
  background-color: #2f2f2f;
  border-radius: .5rem;
  color: white;
  text-decoration: none !important;
  min-height: 130px;
  font-size: 1.1rem;
}

.RowHeader {
  background: rgba(255, 255, 255, .1);
  padding: .25rem .5rem;
  border-color: #5c5c5c !important;
  font-size: 1rem;
}

.RowFooter {
  background: rgba(0, 0, 0, .1);
  border-color: #5c5c5c !important;
  font-size: .9rem;
}

.LocationName {
  /* background: rgba(0, 0, 0, .5); */
  /* padding: .25rem .5rem; */
  font-size: .9rem;
}

.RowFooterItem {
  color: #999999;
  border-color: #5c5c5c !important;
  font-size: 1rem;
}

.KeyboardInputButton {
  align-items: center;
  background: black;
  border-radius: 100%;
  bottom: 47px;
  display: flex;
  height: 60px;
  justify-content: center;
  left: calc(50% + 60px);
  position: fixed;
  width: 60px;
  z-index: 1;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.object-position-top {
  object-position: top;
}

.object-position-bottom {
  object-position: bottom;
}

.object-position-center {
  object-position: center;
}