.navbar-container {
  display: flex;
  justify-content: space-between;
}

.navbar-brand {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  overflow: hidden;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  text-transform: capitalize;
}

.nav-icon {
  height: 38px;
  margin-right: 8px;
}

.navbar {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) !important;
  z-index: 10;
}

.navbar .nav-link {
  cursor: pointer;
}

.navbar .top-nav-trigger {
  padding: 0.75rem 1rem;
  height: 62px;
  display: flex;
  align-items: center;
}

.navbar .form-control {
  border-radius: 0;
  border-width: 0;
  padding: 0.75rem 1rem;
}

.navbar .dropdown-menu {
  position: absolute;
  min-width: 300px;
}

.user-profile img {
  border: 1px solid var(--ships-officer);
  border-radius: 50%;
  margin: 0 1rem;
  height: 30px;
  width: 30px;
}

.topNav-icon-dropdown-btn {
  background: none !important;
  border: 0;
  color: var(--ships-officer);
  font-size: 1.15rem;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
}

.topNav-icon-dropdown-btn[aria-expanded=true] {
  color: var(--electron-blue);
}

.notifications-dropdown {
  right: -93px;
  width: 100vw;
}

@media (min-width: 576px) {
  .notifications-dropdown {
    min-width: 400px;
    width: auto;
    right: 0;
  }
}

#navbarDropdownOrgMenu .dropdown-item i {
  min-width: 20px;
  margin-right: 5px;
  text-align: center;
}