a, .btn-link {
  color: #0984e3;
}

.btn-link {
  border: 0;
  background: none;
}

a.loading, button.loading, .btn.loading {
  pointer-events: none;
  cursor: default;
}

a[disabled], button[disabled], .btn[disabled] {
  pointer-events: none;
}

.btn-primary {
  background-color: #0984e3;
  border-color: #0984e3;
  color: white !important;
}

.btn-primary:hover {
  background-color: rgba(9, 132, 227, .9);
  border-color: #0984e3;
}

.btn-primary:disabled {
  background-color: rgba(9, 132, 227, .5);
  border-color: rgba(9, 132, 227, .5);
  cursor: progress;
}

.btn-white {
  color: #2c3a47;
  background-color: white;
  border-color: #d2d2d2;
}

.btn-white:hover {
  background-color: #f8f9fa;
  border-color: #2c3a47;
}

.btn-emphasis-primary {
  background-color: #fd7272;
  border-color: #fd7272;
  font-family: "Roboto", Sans-serif;
  color: #fff;
  font-weight: 600;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, .1);
}

.btn-emphasis-primary:hover {
  background-color: rgba(253, 114, 114, .9);
  border-color: #fd7272;
  color: #fff;
}

.btn-emphasis-primary:disabled {
  background-color: #cad3c8;
  border-color: #cad3c8;
  cursor: progress;
}

.btn-outline-primary {
  color: #0984e3;
  border-color: #0984e3;
}

.btn-outline-primary:hover {
  background-color: #0984e3;
  border-color: #0984e3;
}

.btn-outline-white {
  color: white;
  border-color: white;
}

.btn-outline-white:hover {
  background-color: white;
  color: #2c3a47;
  border-color: white;
}
