.welcomeMessage {
  opacity: 0;
  filter: blur(4px);
  /* height: 180px; */
}

.welcomeMessage.animateIn {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.welcomeMessage.animateOut {
  animation: fade-out 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.filter-blur-5 {
  filter: blur(5px);
}

.speechly-tooltip {
  font-family: 'Saira Condensed', sans-serif;
  color: white;
  font-size: 1.25rem;
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes fade-out {
  100% {
    height: 0;
    opacity: 0;
  }
}