.theme-dark {
  color: var(--gray-blue);
  /* background-color: black;
  border-color: #23292d !important; */
}

.theme-dark.LogbookForm {
  color: var(--gray-blue);
  background-color: black;
  border-color: #23292d !important;
}

.theme-dark .list-group-item {
  color: #848d97 !important;
  background-color: #0a0c0f !important;
  border: 1px solid #161b22;
}

.theme-dark .tw-bg-white {
  background-color: #161b22 !important;
}

.theme-dark .card-header {
  color: #999999;
  /* background-color: #161b22; */
  border-color: #23292d !important;
}

.theme-dark .card-footer {
  /* background: #161b22; */
  border-color: #23292d !important;
}

/* Form Elements */
.theme-dark input[type="text"],
.theme-dark input[type="number"],
.theme-dark input[type="checkbox"],
.theme-dark textarea {
  color: #c0c7cb !important;
  background-color: #0e1117 !important;
  border-color: #23292e !important;
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #6d7681 !important;
}

::placeholder {
  color: #6d7681 !important;
}

/* Collapse */
.theme-dark .Collapse {
  color: #858585 !important;
  border-color: #23292d !important;
}

.theme-dark .Collapse>div:first-child {
  color: var(--gray-blue) !important;
  border-color: #23292e !important;
  background-color: #0d1015 !important;
}

.theme-dark .Collapse>div[aria-expanded="true"]:first-child {
  background-color: #161b22 !important;
}

.theme-dark .Collapse>div:last-child {
  background-color: #161b22 !important;
  border-color: #23292e;
}

.theme-dark .badge.bg-white,
.theme-dark .badge.badge-primary {
  color: #2c73da !important;
  background-color: #131d2e !important;
  border-color: #23292e !important;
}

.theme-dark .Collapse>div[aria-expanded="true"]:first-child .badge.bg-white,
.theme-dark .Collapse>div[aria-expanded="true"]:first-child .badge.badge-primary {
  border-color: #0984e3 !important;
  border-width: 1px !important;
  border-style: solid !important;
}

/* Competencies */
.theme-dark .Collapse label,
.theme-dark .Collapse label div,
.theme-dark .Collapse .selected-competency {
  color: #e2e2e2 !important;
  background-color: #12171f !important;
  border-color: #384148 !important;
}

.theme-dark .Collapse label.bg-lightblue,
.theme-dark .Collapse label.bg-lightblue div {
  color: var(--gray-blue) !important;
  border-color: #0984e3 !important;
  background-color: #131d2e !important;
}

.theme-dark .input-group-text {
  color: var(--gray-blue) !important;
  background-color: #161b22 !important;
  border-color: #23292e !important;
}