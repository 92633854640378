.orgDashCard {
  text-decoration: none !important;
  min-height: 130px;
  font-size: 1.1rem;
}

.orgDashCard:hover {
  background-color: rgba(54, 162, 235, .2);
  border: 1px rgba(54, 162, 235, 1) solid;
}

.orgDashCard i {
  font-size: 1.5rem;
  margin-bottom: .5rem;
}

@media (min-width: 767.98px) {
  .orgDashCard {
    font-size: 1.5rem;
    min-height: 200px;
  }

  .orgDashCard i {
    font-size: 2rem;
  }
}

/* Dashboard Card Css */
.dashboard-org {
  max-width: 1400px;
  margin: auto;
}

.dashboard-title {
  color: black;
}

.dashboard-subtitle {
  font-size: large;
  font-weight: lighter;
}

.dashboard-card-image {
  width: 100%;
  height: 100%;
}

.DashboardCard {
  text-decoration: none !important;
  position: relative;
  top: 0;
  color: #3f5366 !important;
  transition: top ease 0.5s;
}

.DashboardCard:hover {
  text-decoration: none !important;
  top: -10px;
}

.DashboardCard .card-title {
  font-size: 1.5rem;
}

.DashboardCard .card-text {
  font-size: 1rem;
}

.DashboardCard .card-img-top {
  height: 75px;
  object-fit: cover;
}

@media (min-width: 767.98px) {
  .dashboard-navigate-button-container {
    align-items: center;
  }

  .DashboardCard .card-img-top {
    height: 150px;
  }

  .DashboardCard .card-title {
    font-size: 1.75rem;
  }
}