.turbine-modal-active {
  overflow: hidden !important;
}

.turbine-modal--style-card .modal-content {
  padding: 0 !important;
}

.turbine-modal--style-fullscreen .modal-container {
  height: 100%;
  max-width: none;
}

.turbine-modal--style-fullscreen .modal-content {
  height: 100%;
  margin: 0 !important;
}

.turbine-modal--style-fullscreen .card {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.turbine-modal--style-fullscreen .card-header {
  border-radius: 0;
}

.turbine-modal--style-fullscreen .card-body {
  overflow-y: scroll;
  overflow-x: hidden;
}

.turbine-modal--style-fullscreen iframe {
  border: 0;
  height: 100%;
  width: 100%;
}
