.card-content {
  position: relative;
}

.card-loading-turbine {
  align-items: center;
  background-color: rgba(255, 255, 255, .8);
  color: slategrey;
  display: flex;
  font-size: 3rem;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
