.nav-tabs {
  position: relative;
  top: 1px;
  border: 0;
}

.nav-tabs .nav-link {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
  /* border: 0; */
  background: #161b22;
}

.nav-tabs .nav-link.active {
  background-color: #343a40 !important;
  border-color: #454d55 !important;
  color: white !important;
}

.nav-tabs .nav-link:not(.active):hover {
  border-color: transparent !important;
  /* border: 0; */
  background: #222a34 !important;
}