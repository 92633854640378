.stopwatch-time {
  text-align: center;
  color: #fff;
  font-size: 2rem;
}

.stopwatch-buttons {
  display: flex;
  justify-content: center;
}

.stopwatch-buttons button {
  margin: 20px;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
  color: #fff;
}

.stopwatch-buttons button:first-child {
  background-color: green;
}

.stopwatch-buttons button:last-child {
  background-color: red;
}

.blinker {
  animation: blinker 2.5s linear infinite;
  font-family: sans-serif;
  text-transform: uppercase;
  display: inline-block;
  width: 6px;
  height: 6px;
  background: red;
  border-radius: 50%;
  position: relative;
}

.blinker.isPaused {
  background: grey;
  animation: none;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}