.waveContainer {
  background: #121212;
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.waveContainer.content {
  background: #121212;
  height: calc(100% - 78px);
  position: fixed;
  width: 100%;
  top: 78px;
  left: 0;
  z-index: 100;
}

.waveContainer.contained {
  background: #1d1d1d;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.waveElement {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, cyan, #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}

.waveElement:nth-child(2) {
  animation-delay: 0.1s;
}

.waveElement:nth-child(3) {
  animation-delay: 0.2s;
}

.waveElement:nth-child(4) {
  animation-delay: 0.3s;
}

.waveElement:nth-child(5) {
  animation-delay: 0.4s;
}

.waveElement:nth-child(6) {
  animation-delay: 0.5s;
}

.waveElement:nth-child(7) {
  animation-delay: 0.6s;
}

.waveElement:nth-child(8) {
  animation-delay: 0.7s;
}

.waveElement:nth-child(9) {
  animation-delay: 0.8s;
}

.waveElement:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
