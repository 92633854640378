/* Person Activity Css */
.person-activity-card {
  height: fit-content;
}

.person-profile-image-container {
  border: 3px solid #ffffff;
  height: auto;
  width: 85px;
  border-radius: 50%;
  top: 50%;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.person-activity-card-once {
  background-color: #703688;
  height: 32px;
}

.person-activity-nameblock {
  background-image: linear-gradient(288.99deg,
      #7e58c2 21.28%,
      rgba(172, 139, 232, 0.590259) 70.49%,
      rgba(111, 66, 193, 0) 141.37%);
  padding-top: 50px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* MD */
@media (max-width: 991.98px) {
  .person-activity-profile-image {
    width: 90px;
  }
}