@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fab-btn-bkg {
  position: fixed;
  height: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, .9);
  opacity: 0;
  z-index: 1;
}

.fab-btn-bkg.active {
  animation: fadeInAnimation ease .5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  height: 100%;
  width: 100%;
}

.fab-container {
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 1;
}

.fab-btn {
  position: relative;
  z-index: 12;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: var(--purple);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fab-btn:before {
  position: absolute;
  content: "";
  width: 15px;
  height: 2px;
  background: #fff;
  transform: rotate(90deg);
  transition: all 0.4s ease;
}

.fab-btn:after {
  position: absolute;
  content: "";
  width: 15px;
  height: 2px;
  background: #fff;
  transition: all 0.4s ease;
}

.fab-menu {
  opacity: 0;
  transition: all 0.4s ease-in-out;
  width: 100%;
  transform: translateY(-1000%);
  position: absolute;
}

.fab-menu.active {
  opacity: 1;
  transform: translateY(8%);
}

.fab-btn.active:before {
  transform: rotate(225deg);
}

.fab-btn.active:after {
  transform: rotate(135deg);
}

/* @media (max-width: 640px) {
  .fab-container {
    width: 100%;
  }
} */